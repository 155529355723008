$breakpoints: (
  lg: 1440px,
  md: 1280px,
  lg-tablet: 1025px,
  tablet: 744px,
  mobile: 480px,
);

// Responsive mixin
@mixin responsive($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and (min-width: #{map-get($breakpoints, $breakpoint) - 1}) {
      @content;
    }
  } @else {
    @warn "Invalid breakpoint: #{$breakpoint}. Please use one of #{map-keys($breakpoints)}.";
  }
}
