@import './variable';

@each $fw-name, $fw-value in $spaces {
  .p-#{$fw-name} {
    padding: $fw-value;
  }

  .pt-#{$fw-name} {
    padding-top: $fw-value;
  }

  .pb-#{$fw-name} {
    padding-bottom: $fw-value;
  }

  .pl-#{$fw-name} {
    padding-left: $fw-value;
  }

  .pr-#{$fw-name} {
    padding-right: $fw-value;
  }

  .m-#{$fw-name} {
    margin: $fw-value;
  }

  .mt-#{$fw-name} {
    margin-top: $fw-value;
  }

  .mb-#{$fw-name} {
    margin-bottom: $fw-value;
  }

  .ml-#{$fw-name} {
    margin-left: $fw-value;
  }

  .mr-#{$fw-name} {
    margin-right: $fw-value;
  }

  .gap-#{$fw-name} {
    gap: $fw-value;
  }
}

@media (max-width: $md) {
  @each $fw-name, $fw-value in $spacesTablet {
    .p-#{$fw-name} {
      padding: $fw-value;
    }

    .pt-#{$fw-name} {
      padding-top: $fw-value;
    }

    .pb-#{$fw-name} {
      padding-bottom: $fw-value;
    }

    .pl-#{$fw-name} {
      padding-left: $fw-value;
    }

    .pr-#{$fw-name} {
      padding-right: $fw-value;
    }

    .m-#{$fw-name} {
      margin: $fw-value;
    }

    .mt-#{$fw-name} {
      margin-top: $fw-value;
    }

    .mb-#{$fw-name} {
      margin-bottom: $fw-value;
    }

    .ml-#{$fw-name} {
      margin-left: $fw-value;
    }

    .mr-#{$fw-name} {
      margin-right: $fw-value;
    }

    .gap-#{$fw-name} {
      gap: $fw-value;
    }
  }
}
@media (max-width: $tablet) {
  @each $fw-name, $fw-value in $spacesMobile {
    .p-#{$fw-name} {
      padding: $fw-value;
    }

    .pt-#{$fw-name} {
      padding-top: $fw-value;
    }

    .pb-#{$fw-name} {
      padding-bottom: $fw-value;
    }

    .pl-#{$fw-name} {
      padding-left: $fw-value;
    }

    .pr-#{$fw-name} {
      padding-right: $fw-value;
    }

    .m-#{$fw-name} {
      margin: $fw-value;
    }

    .mt-#{$fw-name} {
      margin-top: $fw-value;
    }

    .mb-#{$fw-name} {
      margin-bottom: $fw-value;
    }

    .ml-#{$fw-name} {
      margin-left: $fw-value;
    }

    .mr-#{$fw-name} {
      margin-right: $fw-value;
    }

    .gap-#{$fw-name} {
      gap: $fw-value;
    }
  }
}
