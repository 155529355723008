$spaces: (
  0: 0rem,
  25: 0.125rem,
  50: 0.25rem,
  75: 0.375rem,
  100: 0.5rem,
  150: 0.75rem,
  200: 1rem,
  250: 1.25rem,
  300: 1.5rem,
  400: 2rem,
  500: 2.5rem,
  600: 3rem,
  700: 3.5rem,
  800: 4rem,
  900: 4.5rem,
  1000: 5rem,
  1100: 5.5rem,
  1200: 6rem,
  1300: 6.5rem,
);

$spacesTablet: (
  0: 0rem,
  25: 0.125rem,
  50: 0.25rem,
  75: 0.375rem,
  100: 0.5rem,
  150: 0.5rem,
  200: 0.75rem,
  250: 1rem,
  300: 1.25rem,
  400: 1.5rem,
  500: 2rem,
  600: 2.5rem,
  700: 3rem,
  800: 3.5rem,
  900: 4rem,
  1000: 4.5rem,
  1100: 5rem,
  1200: 5.5rem,
  1300: 6rem,
);

$spacesMobile: (
  0: 0rem,
  25: 0.125rem,
  50: 0.25rem,
  75: 0.375rem,
  100: 0.5rem,
  150: 0.75rem,
  200: 1rem,
  250: 1.25rem,
  300: 1.25rem,
  400: 1.25rem,
  500: 1.25rem,
  600: 1.5rem,
  700: 2rem,
  800: 2.5rem,
  900: 3rem,
  1000: 3.5rem,
  1100: 4rem,
  1200: 4.5rem,
  1300: 5rem,
);

$radius: (
  'cardRadiusCorner0': 0rem,
  'cardRadiusCorner25': 0.125rem,
  'cardRadiusCorner50': 0.25rem,
  'cardRadiusMenuCardCorner': 0.25rem,
  'cardRadiusCorner75': 0.375rem,
  'cardRadiusCorner100': 0.5rem,
  'cardRadiusCorner200': 1rem,
  'cardRadiusCorner300': 1.5rem,
  'exceptionsInputBottomUnderline': 0rem,
  'exceptionsCheckboxRadiusS': 0.125rem,
  'exceptionsCheckboxRadiusMedium': 0.25rem,
  'exceptionsAvatarRadiusMedium': 0.5rem,
  'exceptionsInputTopRadius': 0.5rem,
  'inputRadius0': 0rem,
  'inputRadius025': 0.125rem,
  'inputRadius050': 0.25rem,
  'inputRadius075': 0.375rem,
  'inputRadius100': 0.5rem,
  'inputRadius150': 0.75rem,
  'inputRadius200': 1rem,
  'paddingDropdownXsLf': 0rem,
  'paddingInputTitle': 0rem,
  'paddingInsideAccordionTpBt': 0rem,
  'paddingLinkButtonXxs': 0.125rem,
  'paddingScrollbarXsTpBt': 0.125rem,
  'paddingFlagPadding': 0.25rem,
  'paddingInputPhContainerRg': 0.25rem,
  'paddingSelectPhContainerLf': 0.25rem,
  'paddingTextarea': 0.25rem,
  'paddingTextareaPaddingLf': 0.25rem,
  'paddingTag': 0.375rem,
  'paddingButtonXs': 0.5rem,
  'paddingDropdownSmallTpBt': 0.5rem,
  'paddingInputIconLf': 0.5rem,
  'paddingSelectIconLf': 0.5rem,
  'paddingButtonSmall': 0.75rem,
  'paddingFlagRg': 0.75rem,
  'paddingPlaceholderContainer': 0.75rem,
  'paddingAccordionLfRg': 1rem,
  'paddingAccordionTpBt': 1rem,
  'paddingButtonMedium': 1rem,
  'paddingInputHint': 1rem,
  'paddingMenuCardRg16': 1rem,
  'paddingButtonLarge': 1.5rem,
  'paddingMenuCardLfRg24': 1.5rem,
  'paddingButtonXl': 2rem,
);

$fonts: (
  'p16': 1rem,
  'p14': 0.88rem,
  'p12': 0.75rem,
  't32': 2rem,
  'b20': 1.25rem,
  'ultra': 5rem,
  'large': 3.5rem,
  'display': 3rem,
  'h1': 2.5rem,
  'h2': 2rem,
  'h3': 1.5rem,
  'h4': 1.25rem,
  'h5': 1rem,
  'h6': 0.88rem,
);

$fontsTablet: (
  'large': 3rem,
  'h1': 2rem,
  'h2': 1.5rem,
  'h3': 1.25rem,
  'h4': 1rem,
);

$fontsMobile: (
  'large': 2.5rem,
  'h1': 1.5rem,
  'h2': 1.25rem,
  'h3': 1rem,
  'h4': 1rem,
);

$lg: 1440px;
$md: 1280px;
$tablet: 744px;
$mobile: 480px;
