@font-face {
  font-family: "FiraGO";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/fonts.woff") format("woff");
}

body {
  margin: 0;
  font-family: "FiraGO", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

@media (max-width: 1279px) {
  body.fixed {
    overflow: hidden;
    height: 100vh;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*:not(.paralax-container *) {
  box-sizing: border-box;
  /* transition: all 0.3s ease-in-out; */
}

a {
  text-decoration: none;
}

::-webkit-scrollbar-track {
  background-color: #091e424f;
}

::-webkit-scrollbar-thumb {
  background-color: #091e42;
  border-radius: 4px;
}

body {
  scrollbar-color: #091e42 #091e424f;
  scrollbar-width: thin;
}

body::-webkit-scrollbar-track {
  background-color: #091e424f;
}

body::-webkit-scrollbar-thumb {
  background-color: #091e42;
  border-radius: 4px;
}
