@import "./variable";

@each $fw-name, $fw-value in $fonts {
  .f-#{$fw-name} {
    font-size: $fw-value;
  }
}

body,
p,
span {
  font-size: map-get($fonts, "p16");
  line-height: 150%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 120%;
  text-transform: inherit;
}

.uppercase {
  text-transform: uppercase;
  font-feature-settings: "case" 1;
}

h1 {
  font-size: map-get($fontsMobile, "h1");
}

h2 {
  font-size: map-get($fontsMobile, "h2");
}

h3 {
  font-size: map-get($fontsMobile, "h3");
}

@include responsive(mobile) {
  .f-large {
    font-size: map-get($fontsTablet, "large");
  }

  h1 {
    font-size: map-get($fontsTablet, "h1");
  }

  h2 {
    font-size: map-get($fontsTablet, "h2");
  }

  h3 {
    font-size: map-get($fontsTablet, "h3");
  }
}

@include responsive(lg) {
  .f-large {
    font-size: map-get($fonts, "large");
  }

  h1 {
    font-size: map-get($fonts, "h1");
  }

  h2 {
    font-size: map-get($fonts, "h2");
  }

  h3 {
    font-size: map-get($fonts, "h3");
  }
}

.color-default {
  color: #172b4d;
}

.color-red {
  color: #e3021b;
}

.color-yellow {
  color: #fcb026;
}
