$alignments: (
  center: center,
  left: start,
  right: end,
  justify: justify,
);

$flexbox-classes: (
  row: row,
  column: column,
  nowrap: nowrap,
  wrap: wrap,
);

$positions: (
  relative: relative,
  absolute: absolute,
  fixed: fixed,
  static: static,
);

// Map for display
$display-styles: (
  block: block,
  inline: inline,
  inline-block: inline-block,
  flex: flex,
  grid: grid,
  none: none,
);

$font-weights: (
  normal: normal,
  bold: bold,
  bolder: bolder,
  lighter: lighter,
  100: 100,
  200: 200,
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
  900: 900,
);

$text-transforms: (
  uppercase: uppercase,
  lowercase: lowercase,
  capitalize: capitalize,
  none: none,
);

$justify-contents: (
  flex-start: flex-start,
  flex-end: flex-end,
  center: center,
  space-between: space-between,
  space-around: space-around,
  space-evenly: space-evenly,
);

$align_items: (
  flex-start: flex-start,
  flex-end: flex-end,
  center: center,
  baseline: baseline,
  stretch: stretch,
);

// Generate align-items classes
@each $key, $value in $align_items {
  .align-items-#{$key} {
    align-items: $value;
  }
}

// Generate text alignment classes
@each $key, $value in $alignments {
  .text-#{$key} {
    text-align: $value;
  }
}

// Generate flexbox classes
@each $key, $value in $flexbox-classes {
  .flex-#{$key} {
    display: flex;
    flex-direction: $value;
  }
}

@each $key, $value in $justify-contents {
  .justify-#{$key} {
    justify-content: $value;
  }
}

@each $pos-key, $pos-value in $positions {
  .position-#{$pos-key} {
    position: $pos-value;
  }
}

// Generate display classes
@each $display-key, $display-value in $display-styles {
  .display-#{$display-key} {
    display: $display-value;
  }
}

@each $weight-key, $weight-value in $font-weights {
  .font-weight-#{$weight-key} {
    font-weight: $weight-value;
  }
}

@each $transform-key, $transform-value in $text-transforms {
  .text-transform-#{$transform-key} {
    text-transform: $transform-value;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.container {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;

  @include responsive(lg-tablet) {
    width: 92%;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  @include responsive(md) {
    max-width: 1128px;
  }

  @include responsive(lg) {
    width: 85%;
    max-width: 1590px;
  }
}

body .hide-lg {
  @media (max-width: $lg) {
    display: none;
  }
}

body .hide-md {
  @media (max-width: $md) {
    display: none;
  }
}

body .hide-tablet {
  @media (max-width: $md) {
    display: none;
  }
}

body .hide-mobile {
  @media (max-width: $mobile) {
    display: none;
  }
}

body .visible-mobile {
  display: none;
  @media (max-width: $tablet) {
    display: inline-block;
  }
}

body .visible-tablet {
  display: none;
  @media (max-width: $md) {
    display: inline-block;
  }
}
